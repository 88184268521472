import axios from 'axios';
import toast from 'react-hot-toast';
import AuthService from 'services/AuthService/AuthService';

let refreshTokenFetching = false;
let failedApiRequests: any[] = [];

export const apiClient = axios.create({
	baseURL: `${process.env.REACT_APP_API_DOMAIN}/${process.env.REACT_APP_API_PATH}`,
	headers: {
		'Content-type': 'application/json'
	}
});

apiClient.interceptors.request.use(
	function (config) {
		if (config.headers) {
			if (!config.headers.Authorization && localStorage.getItem('access_token') !== null) {
				config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
			}
			if (
				!config.headers.Organization &&
				localStorage.getItem('currentOrganizationId') !== null &&
				config.data !== 'globalUsersGet'
			) {
				config.headers.Organization = Number(localStorage.getItem('currentOrganizationId'));
			}
		}
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

apiClient.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		const originalRequest = error.config;
		if (error.response?.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			if (!refreshTokenFetching) {
				failedApiRequests.push(originalRequest);
				refreshTokenFetching = true;
				AuthService.refreshToken().then((res) => {
					if (failedApiRequests.length > 0) {
						const failedApiRequestsCall = failedApiRequests.map((api) => apiClient(api));
						Promise.all(failedApiRequestsCall);
						refreshTokenFetching = false;
						failedApiRequests = [];
					}
					return res;
				});
			}
		} else handleCustomError(error.response.data);

		return Promise.reject(error.response.data);
	}
);

function handleCustomError(errorData: any) {
	if (errorData.form_errors) {
		Object.entries(errorData.form_errors).forEach(([key, value]: [string, any]) => {
			toast.error(value.join(', '));
		});
	} else if (errorData.message) {
		toast.error(errorData.message);
	} else if (errorData.details) {
		toast.error(errorData.details);
	}
}
