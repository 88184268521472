import { Edit, Help, Settings, Visibility } from '@mui/icons-material';
import { Autocomplete, MenuItem, Popper, Select, TextField } from '@mui/material';
import classNames from 'classnames';
import PermissionView from 'components/shared/PermissionsView/PermissionView';
import { useFormik } from 'formik';
import { useGlobalStore } from 'global-state/useStore';
import useStationInfo from 'hooks/useStationDetailsHook';
import { DischargeStationDetails } from 'interfaces/models/DischargeStationDetails';
import { IStationFormProps } from 'pages/SiteDetails/components/StationTabs/components/types';
import React, { FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { queryKeys } from 'rq/constants';
import { useCreateStation, useStationDetails, useUpdateStation } from 'rq/hooks/stationHook';
import { queryClient } from 'rq/queryClient';
import { profileTypes, roughnessCategories } from 'utils/helperData';
import * as Yup from 'yup';
import { hasValueProperty } from 'utils/functions';
import { useParams } from 'react-router-dom';
import PrimaryButton from 'components/shared/Button/PrimaryButton';
import { isEqual } from 'helper/helperFunctions';
import CancelButton from '../../../../../components/shared/Button/CancelButton';
import { PROCESSING_STEPS } from '../../../../CloudProcessing/ProcessingSteps';
import IconWithTooltip from '../../../../../components/shared/Tooltip/IconWithTooltip';

const DischargeStationForm: FunctionComponent<IStationFormProps> = ({
	station_id,
	CPFastFlowForm,
	showOriginalConfigData
}) => {
	const { t } = useTranslation();
	const { siteId } = useParams();
	const currentOrganization = useGlobalStore((state) => state.currentOrganization);
	const toggleEditGCPModal = useGlobalStore((state) => state.toggleEditGCPModal);
	const toggleSetUpCrossSectionModal = useGlobalStore(
		(state) => state.toggleSetUpCrossSectionModal
	);
	const toggleFreeProfileChartModal = useGlobalStore((state) => state.toggleFreeProfileChartModal);
	const toggleEditFreeProfilePointsModal = useGlobalStore(
		(state) => state.toggleEditFreeProfilePointsModal
	);
	const toggleConfirmationDialog = useGlobalStore((state) => state.toggleConfirmationDialog);
	const toggleEditCustomConfig = useGlobalStore((state) => state.toggleEditCustomConfig);
	const stationData = useGlobalStore((state) => state.dischargeStationData);
	const stationDataApplied = useGlobalStore((state) => state.stationChangesApplied);
	const setStationData = useGlobalStore((state) => state.setDischargeStationData);
	const setStationDataApplied = useGlobalStore((state) => state.setStationChangesApplied);

	const currentStep = useGlobalStore((state) => state.currentStep);
	const displayStep = useGlobalStore((state) => state.displayStep);
	const setCurrentStep = useGlobalStore((state) => state.setCurrentStep);
	const setDisplayStep = useGlobalStore((state) => state.setDisplayStep);
	const stationDataChanged = useGlobalStore((state) => state.stationDataChanged);
	const setStationDataChanged = useGlobalStore((state) => state.setStationDataChanged);

	const [submitAction, setSubmitAction] = useState<'apply' | 'update'>('update');

	const { data: stationDetails } = useStationDetails<DischargeStationDetails>(
		Number(siteId),
		station_id,
		{
			enabled: !!station_id
		}
	);

	useEffect(() => {
		if (stationDetails && !stationData) {
			setStationData(stationDetails);
		}
	}, [stationDetails]);

	const [stationFormData, setStationFormData] = useState(stationData);

	useEffect(() => {
		if (showOriginalConfigData) {
			setStationFormData(stationDetails as DischargeStationDetails);
		} else {
			setStationFormData(stationData);
		}
	}, [showOriginalConfigData, stationData]);

	const { manageStationPermission, GCPPointsDefined } = useStationInfo(
		currentOrganization,
		stationData as DischargeStationDetails
	);

	const toggleCreateStationModal = useGlobalStore((state) => state.toggleCreateStationModal);
	const { mutate: createStation } = useCreateStation();
	const { mutate: updateStation } = useUpdateStation();

	const validationSchema = Yup.object().shape({
		profile: Yup.object().shape({
			type: Yup.string().required(t('REQUIRED')),
			depth: Yup.number()
				.min(0)
				.required()
				.when('profile.type', {
					is: (profileType: string) => profileType !== 'FREE',
					then: Yup.number().required(t('REQUIRED'))
				}),
			roughness: Yup.number().required().min(20),
			width: Yup.number()
				.min(0)
				.required()
				.when('profile.type', {
					is: (profileType: string) => profileType !== 'FREE',
					then: Yup.number().required(t('REQUIRED'))
				}),
			bottom_width: Yup.number()
				.min(0)
				.required()
				.when('profile.type', {
					is: (profileType: string) => profileType === 'TRAPEZOIDAL',
					then: Yup.number().required(t('REQUIRED'))
				})
		}),
		video_resolution: Yup.string().required()
	});

	const formik = useFormik({
		initialValues: {
			station_name: stationFormData?.station_name ?? null,
			station_type: stationFormData?.station_type ?? 'DISCHARGE',
			image: stationFormData?.image ?? '',
			profile: {
				type: stationFormData?.profile.type ?? 'FREE',
				depth: stationFormData?.profile.depth ?? 0,
				roughness: stationFormData?.profile.roughness ?? 20,
				width: stationFormData?.profile.width ?? 0,
				bottom_width: stationFormData?.profile.bottom_width ?? 0,
				free_params: stationFormData?.profile.free_params ?? []
			},
			markers_coordinates: stationFormData?.markers_coordinates ?? [],
			marker_measurements: stationFormData?.markers_measurements ?? [],
			video_resolution: stationFormData?.video_resolution ?? 720
		},
		validationSchema: validationSchema,
		onSubmit: (data) => {
			if (data.profile.type === 'FREE') {
				data.profile.width = 0;
				data.profile.depth = 0;
				data.profile.bottom_width = 0;
			} else if (data.profile.type !== 'TRAPEZOIDAL') {
				data.profile.bottom_width = 0;
			}

			if (data.profile.type !== 'FREE') {
				data.profile.free_params = [];
			}

			if (
				!CPFastFlowForm ||
				(CPFastFlowForm &&
					currentStep.name === 'RESULTS' &&
					displayStep.name === 'RESULTS' &&
					submitAction === 'update')
			) {
				station_id &&
					updateStation(
						{
							siteId: Number(siteId),
							stationId: station_id,
							data: data
						},
						{
							onSuccess: () => {
								setStationDataApplied(true);
								toast.success(t('STATION_UPDATED'));
								queryClient.invalidateQueries([queryKeys.station_details, `site-id-${siteId}`]);
							}
						}
					);
				!station_id &&
					createStation(
						{
							siteId: Number(siteId),
							data: data
						},
						{
							onSuccess: () => {
								toast.success(t('STATION_CREATED'));
								toggleCreateStationModal();
								queryClient.invalidateQueries([queryKeys.stations, `site-${siteId}`]);
							}
						}
					);
			} else {
				setStationData({ station_id, ...data });
				setStationDataApplied(true);
				if (currentStep.name === 'WATER_LEVEL' || currentStep.name === 'RESULTS') {
					setCurrentStep(PROCESSING_STEPS[1]);
					setDisplayStep(PROCESSING_STEPS[1]);
				}
			}
		},
		enableReinitialize: true
	});

	const resetFormData = () => {
		if (stationDetails) {
			formik.resetForm();
			if (currentStep.name === 'WATER_LEVEL' || currentStep.name === 'RESULTS') {
				setCurrentStep(PROCESSING_STEPS[1]);
				setDisplayStep(PROCESSING_STEPS[1]);
			}
			setStationData(stationDetails);
		}
	};

	useEffect(() => {
		setStationDataChanged(!isEqual(formik.values, formik.initialValues));
		setStationDataApplied(isEqual(formik.values, formik.initialValues));
	}, [formik.values, formik.initialValues]);

	console.log(
		'stationData',
		stationData,
		'stationDetails',
		stationDetails,
		'stationFormData',
		stationFormData
	);

	return (
		<form onSubmit={formik.handleSubmit} autoComplete={'off'} className={'h-full'}>
			<div className={'mt-4 flex h-full flex-col gap-10 md:gap-5'}>
				<div className={'flex w-full flex-col lg:flex-row'}>
					<div className={'flex items-center align-middle max-md:text-lg max-md:font-bold md:w-60'}>
						{t('PROFILE')}
					</div>
					<div className={'flex w-full gap-4 normal-case max-md:flex-col'}>
						<Select
							className={'h-10 w-full max-w-[15rem]'}
							name={'profile.type'}
							value={formik.values.profile?.type ?? 'FREE'}
							onChange={formik.handleChange}
							disabled={!manageStationPermission}
							onBlur={formik.handleBlur}
							error={formik.touched.profile?.type && Boolean(formik.errors.profile?.type)}>
							{profileTypes.map((profileType) => (
								<MenuItem value={profileType} key={profileType}>
									<span>
										{profileType.toLowerCase().charAt(0).toUpperCase() +
											profileType.toLowerCase().slice(1)}
									</span>
								</MenuItem>
							))}
						</Select>
						<div
							className={
								'flex items-center gap-3 rounded-lg border border-gray-400 bg-neutral-200 px-4 align-middle text-xs max-md:mt-2 max-md:py-2 max-sm:w-[15rem] max-sm:flex-col md:h-full'
							}>
							{formik.values.profile.type !== 'FREE' ? (
								<>
									<span>{t('WIDTH_M')}</span>
									<TextField
										variant={'standard'}
										className={'h-8 text-sm md:max-w-[5rem]'}
										type={'number'}
										disabled={!manageStationPermission}
										required={true}
										name={'profile.width'}
										value={formik.values.profile?.width}
										InputProps={{
											inputProps: { min: 0 },
											style: { fontSize: 'medium' }
										}}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.profile?.width && Boolean(formik.errors.profile?.width)}
									/>
									<div>{t('DEPTH_M')}</div>
									<TextField
										variant={'standard'}
										className={'h-8 md:max-w-[5rem]'}
										type={'number'}
										name={'profile.depth'}
										value={formik.values.profile?.depth}
										InputProps={{
											inputProps: { min: 0 }
										}}
										onChange={formik.handleChange}
										disabled={!manageStationPermission}
										onBlur={formik.handleBlur}
										error={formik.touched.profile?.depth && Boolean(formik.errors.profile?.depth)}
									/>
									{formik.values.profile.type === 'TRAPEZOIDAL' && (
										<>
											{' '}
											<span>{t('BOTTOM_WIDTH_M')}</span>
											<TextField
												variant={'standard'}
												className={'h-8 w-auto md:max-w-[5rem]'}
												type={'number'}
												name={'profile.bottom_width'}
												value={formik.values.profile?.bottom_width}
												InputProps={{
													inputProps: { min: 0 }
												}}
												onChange={formik.handleChange}
												disabled={!manageStationPermission}
												onBlur={formik.handleBlur}
												error={
													formik.touched.profile?.bottom_width &&
													Boolean(formik.errors.profile?.bottom_width)
												}
											/>
										</>
									)}
								</>
							) : (
								<>
									<div className={'flex w-full items-center gap-3 align-middle'}>
										<div className={'flex h-full flex-row flex-wrap gap-6 md:p-2'}>
											<div
												className={classNames(
													{
														'flex items-center gap-2 align-middle': true
													},
													{ 'cursor-pointer hover:opacity-50': !!stationDetails }
												)}
												onClick={() => {
													setStationData({ station_id, ...formik.values });
													stationDetails &&
														toggleFreeProfileChartModal({
															open: true,
															station_id: stationDetails?.station_id
														});
												}}>
												<Visibility className={'text-gray-500'} /> {t('PREVIEW')}
											</div>
											<PermissionView
												requiredPermissions={['manage_sites']}
												showFallbackComponent={false}>
												<>
													<div
														className={classNames(
															{
																'flex items-center gap-2 align-middle': true
															},
															{ 'cursor-pointer hover:opacity-50': !!stationDetails }
														)}
														onClick={() => {
															setStationData({ station_id, ...formik.values });
															stationDetails &&
																toggleSetUpCrossSectionModal({
																	open: true,
																	station_id: stationDetails.station_id
																});
														}}>
														<Settings className={'text-gray-500'} /> {t('CONFIGURE')}
														<></>
													</div>
													<div
														className={classNames(
															{
																'flex items-center gap-2 align-middle': true
															},
															{ 'cursor-pointer hover:opacity-50': !!stationDetails }
														)}
														onClick={() => {
															setStationData({ station_id, ...formik.values });
															stationDetails &&
																toggleEditFreeProfilePointsModal({
																	open: true,
																	station_id: stationDetails.station_id
																});
														}}>
														<Edit className={'text-gray-500'} /> {t('EDIT')}
													</div>
												</>
											</PermissionView>
										</div>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
				<div className={'flex w-full max-md:flex-col'}>
					<div className={'flex items-center max-md:text-lg max-md:font-bold md:w-60'}>
						{t('GCP')}
					</div>
					<div className={'flex w-full max-md:flex-col'}>
						<div className={'mr-4 flex h-full w-full max-w-[10rem] items-center align-middle'}>
							{GCPPointsDefined
								? `${GCPPointsDefined} ${t('POINTS_DEFINED')}`
								: t('NO_POINTS_DEFINED')}
						</div>
						<PermissionView requiredPermissions={['manage_sites']} showFallbackComponent={false}>
							<div className={'flex h-full flex-row flex-wrap gap-2 md:p-2'}>
								{/*	<div className={'mr-4 flex cursor-pointer items-center gap-2 align-middle'}>
									<Visibility className={'text-gray-500'} /> {t('PREVIEW')}
								</div>*/}
								<div
									className={'flex cursor-pointer items-center gap-2 align-middle'}
									onClick={() => {
										setStationData({ station_id, ...formik.values });
										stationDetails &&
											toggleEditGCPModal({
												open: true,
												station_id: stationDetails.station_id,
												stationDetails: stationDetails
											});
									}}>
									<Settings className={'text-gray-500'} /> {t('CONFIGURE')}
								</div>
							</div>
						</PermissionView>
					</div>
				</div>
				<div className={' flex w-full max-md:flex-col md:h-full'}>
					<div className={'flex items-center gap-2 max-md:text-lg max-md:font-bold md:w-60'}>
						{t('ROUGHNESS')}
					</div>
					<div className={'flex w-full  items-center gap-2 '}>
						<Autocomplete
							freeSolo={true}
							options={roughnessCategories}
							disabled={!manageStationPermission}
							groupBy={(option) => option.group}
							getOptionLabel={(option) =>
								hasValueProperty(option)
									? option.value.toString()
									: formik.values.profile.roughness.toString()
							}
							renderOption={(props, option) => {
								return (
									<span {...props} key={`${option.name} - ${option.value}`}>
										{t(option.name) + ` (${option.displayValue})`}
									</span>
								);
							}}
							value={
								roughnessCategories
									.find((option) => option.value === formik.values.profile.roughness)
									?.value.toString() ?? formik.values.profile.roughness?.toString()
							}
							className={'h-10 w-full max-w-[15rem]'}
							onBlur={formik.handleBlur}
							onChange={(e, newValue: any) => {
								formik.setFieldValue(
									'profile.roughness',
									!isNaN(Number(newValue?.value)) ? Number(newValue?.value) : Number(newValue)
								);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									name={'profile.roughness'}
									className={'w-full max-w-[15rem]'}
									disabled={!manageStationPermission}
									size={'small'}
									onChange={(e) =>
										formik.setFieldValue('profile.roughness', Number(e.target.value))
									}
									value={formik.values.profile?.roughness.toString()}
								/>
							)}
							renderGroup={(params) => (
								<li key={params.key} className={'flex flex-col gap-2'}>
									<div className={'px-2 font-bold'}>{t(params.group)}</div>
									<div>{params.children}</div>
								</li>
							)}
							PopperComponent={(props) => (
								<Popper {...props} style={{ width: 'fit-content' }} placement={'bottom-start'} />
							)}
						/>
						{/*	<Help className={'text-gray-500'}></Help>*/}
					</div>
				</div>
				{!CPFastFlowForm && (
					<>
						<PermissionView requiredPermissions={['manage_sites']} showFallbackComponent={false}>
							<div className={' flex h-10 w-full items-center align-middle  max-md:flex-col'}>
								<div className={'flex items-center max-md:text-lg max-md:font-bold md:w-60'}>
									{t('CUSTOM_CONFIGURATION')}
								</div>

								<div className={'w-full'}>
									<div className={'flex cursor-pointer items-center gap-2 align-middle'}>
										<Settings
											className={'text-gray-500'}
											onClick={() => toggleEditCustomConfig(true, Number(station_id))}
										/>
										<span className={'cursor-auto'}>{t('CONFIGURE')}</span>{' '}
										{/*		<Help className={'text-gray-500'}></Help>*/}
									</div>
								</div>
							</div>
						</PermissionView>
					</>
				)}
				{!showOriginalConfigData && (
					<div className={'w-full'}>
						{!CPFastFlowForm ? (
							<PermissionView requiredPermissions={['manage_sites']} showFallbackComponent={false}>
								<div className={'mt-4 flex justify-center gap-4'}>
									<PrimaryButton
										type={'submit'}
										disabled={
											station_id
												? !formik.isValid ||
												  !manageStationPermission ||
												  (!formik.dirty &&
														JSON.stringify(stationData) === JSON.stringify(stationDetails))
												: !manageStationPermission
										}
										className={'bg-secondary  text-primary disabled:bg-gray-500'}>
										{station_id ? t('UPDATE') : t('CREATE')}
									</PrimaryButton>{' '}
									<CancelButton
										onClick={() => resetFormData()}
										className={'w-fit'}
										disabled={
											isEqual(formik.values.profile, stationDetails?.profile) &&
											isEqual(
												formik.values.markers_coordinates,
												stationDetails?.markers_coordinates
											) &&
											!stationDataChanged
										}>
										{t('RESET')}
									</CancelButton>
								</div>
							</PermissionView>
						) : (
							<div className={'flex w-full flex-wrap justify-between gap-2 '}>
								<div className={' flex gap-2'}>
									<PrimaryButton
										onClick={() => {
											toggleConfirmationDialog({
												type: 'confirmation',
												confirmAction: () => {
													setSubmitAction('apply');
													formik.handleSubmit();
												},
												dialogOpen: true,
												headerTitle: t('RECONFIGURE'),
												message:
													displayStep.name === 'CAMERA_CALIBRATION'
														? t('STATION_FINE_TUNE_GCP_TIP')
														: t('STATION_FINE_TUNE_TIP'),
												confirmActionLabel: t('RECONFIGURE'),
												cancelActionLabel: t('CANCEL')
											});
											/*			setSubmitAction('apply');
														formik.handleSubmit();*/
										}}
										className={'w-fit'}
										disabled={
											(isEqual(formik.values.profile, stationDetails?.profile) &&
												isEqual(
													formik.values.markers_coordinates,
													stationDetails?.markers_coordinates
												) &&
												!stationDataChanged) ||
											stationDataApplied
										}>
										{t('RECONFIGURE')}
									</PrimaryButton>
									<CancelButton
										onClick={() => {
											toggleConfirmationDialog({
												type: 'confirmation',
												confirmAction: () => {
													resetFormData();
												},
												dialogOpen: true,
												headerTitle: t('RESET'),
												message: t('RESET_STATION_CONFIRMATION'),
												confirmActionLabel: t('RESET'),
												cancelActionLabel: t('CANCEL')
											});
										}}
										className={'w-fit'}
										disabled={
											isEqual(formik.values.profile, stationDetails?.profile) &&
											isEqual(
												formik.values.markers_coordinates,
												stationDetails?.markers_coordinates
											) &&
											!stationDataChanged
										}>
										{t('RESET')}
									</CancelButton>
									<IconWithTooltip
										title={
											displayStep.name === 'CAMERA_CALIBRATION'
												? t('STATION_FINE_TUNE_GCP_TIP')
												: t('STATION_FINE_TUNE_TIP')
										}
										icon={<Help className={'text-gray-500'} />}
									/>
								</div>
								{currentStep.name === 'RESULTS' && displayStep.name === 'RESULTS' && (
									<PrimaryButton
										type={'submit'}
										onClick={() => {
											setSubmitAction('update');
											formik.handleSubmit();
										}}
										disabled={
											station_id
												? !formik.isValid ||
												  !manageStationPermission ||
												  (isEqual(formik.values.profile, stationDetails?.profile) &&
														isEqual(
															formik.values.markers_coordinates,
															stationDetails?.markers_coordinates
														))
												: !manageStationPermission
										}
										className={'bg-secondary  text-primary disabled:bg-gray-300'}>
										{station_id ? t('UPDATE_STATION') : t('CREATE')}
									</PrimaryButton>
								)}
							</div>
						)}
					</div>
				)}
			</div>
		</form>
	);
};
export default DischargeStationForm;
